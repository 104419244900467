import { Navigate, useRoutes } from 'react-router-dom';

// import MainLayout from 'src/layouts/main';

// import { PATH_AFTER_LOGIN } from 'src/config-global';
import { paths } from '../paths';
import { mainRoutes } from './main';
import { authRoutes } from './auth';
// import { authDemoRoutes } from './auth-demo';
import { vendorRoutes } from './vendor-routes';
import { operatorRoutes } from './operator-routes';
import { evaluatorRoutes } from './evaluator-routes';
import { clusterAdminRoutes } from './cluster-admin-routes';
// import { componentsRoutes } from './components';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // SET INDEX PAGE WITH SKIP HOME PAGE
    {
      path: '/',
      element: <Navigate to={paths.auth.jwt.login} replace />,
    },

    // ----------------------------------------------------------------------

    // SET INDEX PAGE WITH HOME PAGE
    // {
    //   path: '/',
    //   element: (
    //     <MainLayout>
    //       <HomePage />
    //     </MainLayout>
    //   ),
    // },

    // Auth routes
    ...authRoutes,
    // ...authDemoRoutes,

    // Cluster Admin routes
    clusterAdminRoutes,

    // Operator routes
    operatorRoutes,

    // Evaluator routes
    evaluatorRoutes,

    // Vendor routes
    vendorRoutes,

    // Main routes
    ...mainRoutes,

    // Components routes
    // ...componentsRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
