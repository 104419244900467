import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router';

import { identifyRoles } from 'src/utils/generate-paths';

import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const OverviewEvaluatorPage = lazy(() => import('src/pages/evaluator/statistics'));

// ----------------------------------------------------------------------

const identifiedPaths = identifyRoles('evaluator');

// ----------------------------------------------------------------------

export const evaluatorRoutes = {
  path: 'evaluator',
  element: (
    <AuthGuard>
      <RoleBasedGuard hasContent roles={['EVALUATOR']} sx={{ py: 10 }}>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </RoleBasedGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to="/evaluator/dashboard/statistics" replace />, index: true },
    {
      path: 'dashboard',
      children: [
        { element: <Navigate to="/evaluator/dashboard/statistics" replace />, index: true },
        {
          path: 'statistics',
          element: (
            <OverviewEvaluatorPage
              title="menu.dashboard"
              activeRole="roles.EVALUATOR"
              paths={identifiedPaths!}
            />
          ),
        },
      ],
    },
  ],
};
