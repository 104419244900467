export const en = {
  button: {
    add_vendor: 'Add Vendor',
    view: 'View',
    edit_vendor: 'Edit Vendor',
    delete_vendor: 'Delete Vendor',
    approve: 'Approve',
    reject: 'Reject Vendor',
    upload_new_invoice: 'Upload New Invoice',
  },
  form: {
    name: {
      label: "Vendor's Name",
      required: "Vendor's name is required",
    },
    field: {
      label: 'Field of the Company',
      required: 'Field of the Company is required',
    },
    type_project_handled: {
      label: 'Type of Project Handle',
      required: 'Type of Project Handle is required',
    },
    logo: {
      label: 'Upload Company Logo',
      required: 'Company Logo is required.',
    },
    similiar_project: {
      label: 'Upload Similiar Project',
      required: 'Similiar project is required.',
    },
    analities: {
      label: 'Upload any files that help us understand your analities better',
      required: 'Analities file is required.',
    },
    date_added: {
      label: 'Date Added',
    },
    email: {
      label: 'Email',
    },
    finished_campaigns: {
      label: 'Finished Campaigns',
    },
    fired_campaigns: {
      label: 'Fired Campaigns',
    },
    income_status: {
      label: 'Income Status',
    },
  },
};
