export const en = {
  form: {
    vendor_name: {
      label: 'To Vendor',
      required: 'Vendor name is required',
    },
    selected_invoice: {
      label: 'Select Invoice',
      required: 'You need select on of Invoice',
    },
    reason_to_pay: {
      label: 'Reason to Pay',
      required: 'You need a reason for pay this Vendor',
    },
  },
};
