export const en = {
  title: {
    nearby_places: 'Nearby Places',
    add_nearby_place: 'Add Nearby Place',
    action: 'Action',
    last_update: 'Last Update',
    condition: 'Condition',
    filter: 'Filter',
    sort_by: 'Sort By',
    oldest: 'Oldest',
    newest: 'Newest',
  },
  link: {
    step1: 'Step 1',
    step2: 'Step 2',
    getting_location: 'Getting your location ...',
  },
  form: {
    name: {
      label: 'Name of The Project',
      required: 'Name of The Project is required',
    },
    operator: {
      label: 'Select Operator',
      required: 'Please select operator',
    },
    address: {
      label: 'Place Address',
      required: 'Address is required',
    },
    description: {
      label: 'Description',
      required: 'Description is required',
    },
    start_at: {
      label: 'Start Date',
      required: 'Start Date is required',
    },
    end_at: {
      label: 'End Date',
      required: 'End Date is required',
    },
    country: {
      label: 'Your Location',
      required: 'Your location is required',
    },
    cover: {
      label: 'Upload Thumbnail',
      required: 'Thumbnail is required',
    },
    thumbnail: {
      label: 'Upload Project Images',
      required: 'Project Images is required',
    },
    diameter: {
      label: 'Diameters',
      required: 'Diameters is required',
      min_1: 'Number must be greater than 1',
    },
    prayer_capacity: {
      label: 'Prayer Capacity',
      required: 'Prayer Capacity is required',
      min_1: 'Number must be greater than 1',
      from: 'From',
      to: 'To',
    },
    number_of_toilet: {
      label: 'Number of Toilettes',
      required: 'Number of Toilettes is required',
      min_1: 'Number must be greater than 1',
    },
    has_ac: {
      label: 'Has AC Condition?',
      required: 'Has AC Condition is required',
      yes: 'Yes',
      no: 'No',
    },
    has_classroom: {
      label: 'Has Classroom?',
      required: 'Has Classroom is required',
      yes: 'Yes',
      no: 'No',
    },
    has_parking: {
      label: 'Has Parking?',
      required: 'Has Parking is required',
      yes: 'Yes',
      no: 'No',
    },
    has_green_space: {
      label: 'Has Green Space?',
      required: 'Has Green Space is required',
      yes: 'Yes',
      no: 'No',
    },
    has_female_section: {
      label: 'Has Female Section?',
      required: 'Has Female Section is required',
      yes: 'Yes',
      no: 'No',
    },
    place_name: {
      label: 'Place Name',
      required: 'Place Name is required',
    },
    place_type: {
      label: 'Place Type',
      required: 'Place Type is required',
    },
    distance: {
      label: 'Distance',
      required: 'Distance is required',
    },
    button: {
      next: 'Next',
      back: 'Back',
      add_nearby_place: 'Add Nearby Place',
      submit_project: 'Submit Project',
      submit_place: 'Submit Place',
      save_changes: 'Save Changes',
      apply: 'Apply',
      reset_filter: 'Reset Filter',
      approve_edit: 'Approve Edit',
      reject_edit: 'Reject Edit',
      request_submit_project: 'Request to Submit Project',
      save_project: 'Save Project',
    },
  },
};
