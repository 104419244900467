export const en = {
  dashboard: 'Dashboard',
  statistics: 'Statistics',
  project: {
    title: 'Projects',
    all_project: 'All Project',
    add_project: 'Add Project',
    my_projects: 'My Projects',
    details: 'Details Project',
    edit: 'Project Edit',
    edit_request: 'Project Edit Request',
    edit_project: 'Edit Project',
    delete_project: 'Delete Project',
    project_requests: 'Project Requests',
  },
  campaign: {
    title: 'Campaigns',
    all_campaign: 'All Campaign',
    add_campaign: 'Add Campaign',
    my_campaigns: 'My Campaigns',
    details: 'Campaign Details',
    edit_campaign: 'Edit Campaign',
    payment_history: 'Payment History',
    task_volunteers: 'Task and Volunteers',
    new_ticket: 'New Ticket',
    delete_campaign: 'Delete Campaign',
    edit_request: 'Campaign Edit Request',
    donor_list_campaign: 'Donor List of Campaign',
    donor_list_project: 'Donor List of Project',
    vendor_list_campaign: 'List of Campaigns',
    vendor_favorites_campaign: 'Favorite Campaigns',
    status: 'Campaign Status',
    vendor_requests: 'Vendor Requests',
    detail_vendor_request: 'Detail Vendor Requests',
    vendor_lists: 'Vendor Lists',
  },
  marketplace: {
    title: 'Marketplace',
    all_item: 'All Items',
    add_item: 'Add Item',
    details: 'Project Item Details',
    edit_item: 'Edit Item',
    edit_request_item: 'Edit Request',
    donor_list_item: 'Donor List of Project Items',
  },
  donors: {
    title: 'Donors',
    all_donor: 'All Donors',
    donor_overview: 'Donor Overview',
  },
  vendors: {
    title: 'Vendors',
    all_vendor: 'All Vendor',
    vendor_requests: 'Vendor Requests',
    add_vendor: 'Add New Vendor',
    detail_vendor: 'Vendor Detail',
    detail_requests_vendor: 'Vendor Request Detail',
    milestone_progress: 'Milestone Progress',
    milestone_invoices: 'Milestone Progress Invoices',
    payment_history_progress: 'Payment History Progress',
  },
  operators: 'Operators',
  evaluator: 'Evaluator',
  tickets: 'Tickets',
  directories: 'Directories',
  maintenance_request: 'Maintenance Request',
  community: 'Community',
  blogs: 'Blogs',
  items: 'Items',
  request: 'Requests',
  more_options: 'More Options',
  select_your_options: 'Select Your Options',
  operator_page: 'Operator Page',
  campaign_public_page: "Campaign's Public Page",
  parent_project_page: 'Parent Project Page',
  project_public_page: "Project's Public Page",
  logout: 'Logout',
};
