export const en = {
  form: {
    donor_name: {
      label: "Donor's Name",
    },
    email: {
      label: 'Email',
    },
    date_joined: {
      label: 'Date Joined',
    },
  },
  label: {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
    CAMPAIGN: 'Campaign',
    ITEM: 'Project Item',
    SILVER: 'Zakat',
    GOLD: 'Zakat',
  },
};
