export const ar = {
  title: {
    nearby_places: 'الاماكن المجاورة',
    add_nearby_place: 'إضافة مكان قريب',
    action: 'فعل',
    last_update: 'اخر تحديث',
    condition: 'حالة',
    filter: 'منقي',
    sort_by: 'ترتيب حسب',
    oldest: 'أقدم',
    newest: 'أحدث',
  },
  link: {
    step1: 'الخطوة 1',
    step2: 'الخطوة 2',
    getting_location: 'الحصول على موقعك',
  },
  form: {
    name: {
      label: 'اسم المشروع',
      required: 'اسم المشروع مطلوب',
    },
    operator: {
      label: 'حدد المشغل',
      required: 'الرجاء تحديد المشغل',
    },
    address: {
      label: 'عنوان المكان',
      required: 'العنوان مطلوب',
    },
    description: {
      label: 'وصف',
      required: 'الوصف مطلوب',
    },
    start_at: {
      label: 'تاريخ البدء',
      required: 'تاريخ البدء مطلوب',
    },
    end_at: {
      label: 'تاريخ الانتهاء',
      required: 'تاريخ الانتهاء مطلوب',
    },
    country: {
      label: 'موقعك',
      required: 'موقعك مطلوب',
    },
    cover: {
      label: 'تحميل الصورة المصغرة',
      required: 'الصورة المصغرة مطلوبة',
    },
    thumbnail: {
      label: 'تحميل صور المشروع',
      required: 'صور المشروع مطلوبة',
    },
    diameter: {
      label: 'أقطار',
      required: 'مطلوب أقطار',
      min_1: 'يجب أن يكون الرقم أكبر من 1',
    },
    prayer_capacity: {
      label: 'سعة الصلاة',
      required: 'مطلوب قدرة الصلاة',
      min_1: 'يجب أن يكون الرقم أكبر من 1',
      from: 'من',
      to: 'ل',
    },
    number_of_toilet: {
      label: 'عدد المراحيض',
      required: 'مطلوب عدد المراحيض',
      min_1: 'يجب أن يكون الرقم أكبر من 1',
    },
    has_ac: {
      label: 'بها حالة تكييف',
      required: 'مطلوب حالة التيار المتردد',
      yes: 'نعم',
      no: 'لا',
    },
    has_classroom: {
      label: 'لديه الفصول الدراسية',
      required: 'مطلوب فئة',
      yes: 'نعم',
      no: 'لا',
    },
    has_parking: {
      label: 'يوجد موقف سيارات',
      required: 'مطلوب موقف سيارات',
      yes: 'نعم',
      no: 'لا',
    },
    has_green_space: {
      label: 'بها مساحة خضراء',
      required: 'مطلوب مساحة خضراء',
      yes: 'نعم',
      no: 'لا',
    },
    has_female_section: {
      label: 'يوجد قسم للنساء',
      required: 'مطلوب قسم نسائي',
      yes: 'نعم',
      no: 'لا',
    },
    place_name: {
      label: 'اسم مكان',
      required: 'اسم المكان مطلوب',
    },
    place_type: {
      label: 'نوع المكان',
      required: 'نوع المكان مطلوب',
    },
    distance: {
      label: 'مسافة',
      required: 'المسافة مطلوبة',
    },
    button: {
      next: 'التالي',
      back: 'خلف',
      add_nearby_place: 'إضافة مكان قريب',
      submit_project: 'إرسال المشروع',
      submit_place: 'إرسال المكان',
      save_changes: 'حفظ التغييرات',
      apply: 'يتقدم',
      reset_filter: 'تصفية إعادة تعيين',
      approve_edit: 'الموافقة على التحرير',
      reject_edit: 'رفض التحرير',
      request_submit_project: 'طلب إرسال المشروع',
      save_project: 'احفظ المشروع',
    },
  },
};
