export const en = {
  title: {
    all_evaluation_request: 'All Evaluation Request',
  },
  form: {
    mosque_name: {
      label: 'Mosque’s Name',
    },
    location: {
      label: 'Locations',
    },
    request_date: {
      label: 'Request Date',
    },
  },
};
