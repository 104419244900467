export const ar = {
  form: {
    donor_name: {
      label: 'اسم المتبرع',
    },
    email: {
      label: 'بريد إلكتروني',
    },
    date_joined: {
      label: 'تاريخ الانضمام',
    },
  },
  label: {
    DAILY: 'يوميًا',
    WEEKLY: 'أسبوعي',
    MONTHLY: 'شهريا',
    YEARLY: 'سنوي',
    CAMPAIGN: 'حملة',
    ITEM: 'عنصر المشروع',
    SILVER: 'زكاة',
    GOLD: 'زكاة',
  },
};
