export const ar = {
  form: {
    vendor_name: {
      label: 'إلى البائع',
      required: 'مطلوب اسم البائع',
    },
    selected_invoice: {
      label: 'حدد فاتورة',
      required: 'تحتاج إلى تحديد على الفاتورة',
    },
    reason_to_pay: {
      label: 'سبب للدفع',
      required: 'أنت بحاجة إلى سبب لدفع هذا البائع',
    },
  },
};
