import { lazy, Suspense } from 'react';
import { Outlet, Navigate } from 'react-router';

import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard, RoleBasedGuard } from 'src/auth/guard';

import { LoadingScreen } from 'src/components/loading-screen';

import { PATHS_DASHBOARD } from '../paths';

// ----------------------------------------------------------------------

// OVERVIEW
const OverviewVendorPage = lazy(() => import('src/pages/vendor/statistics'));

// CAMPAIGN
const AllCampaignPage = lazy(() => import('src/pages/campaign/all'));
const AllCampaignCardPage = lazy(() => import('src/pages/campaign/all-card'));
const CampaignMilestonesPage = lazy(() => import('src/pages/campaign/milestones'));
const DetailCampaignCardPage = lazy(() => import('src/pages/campaign/details-card'));

// Vendor
const VendorAcceptedMilestoneProgeressPage = lazy(
  () => import('src/pages/vendor/accepted-milestone-progress')
);
const VendorMilestoneInvoicesPage = lazy(() => import('src/pages/vendor/milestone-invoices'));
const VendorPaymentHistoryProgressPage = lazy(
  () => import('src/pages/vendor/payment-history-progress')
);

// Operators
const AllOperatorPage = lazy(() => import('src/pages/operator/all'));

// ----------------------------------------------------------------------

const identifiedPaths = PATHS_DASHBOARD('VENDOR');

// ----------------------------------------------------------------------

export const vendorRoutes = {
  path: 'vendor',
  element: (
    <AuthGuard>
      <RoleBasedGuard hasContent roles={['VENDOR']} sx={{ py: 10 }}>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </RoleBasedGuard>
    </AuthGuard>
  ),
  children: [
    { element: <Navigate to="/vendor/dashboard/statistics" replace />, index: true },
    {
      path: 'dashboard',
      children: [
        { element: <Navigate to="/vendor/dashboard/statistics" replace />, index: true },
        {
          path: 'statistics',
          element: <OverviewVendorPage title="menu.dashboard" activeRole="roles.VENDOR" />,
        },
      ],
    },
    {
      path: 'campaign',
      children: [
        { element: <Navigate to="/vendor/campaign/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllCampaignCardPage
              title="menu.campaign.all_campaign"
              activeRole="roles.VENDOR"
              paths={identifiedPaths}
            />
          ),
        },
        {
          path: 'my-campaigns',
          element: (
            <AllCampaignPage
              title="menu.campaign.my_campaigns"
              activeRole="roles.VENDOR"
              paths={identifiedPaths}
            />
          ),
        },
        {
          path: 'detail',
          children: [
            { element: <Navigate to="/vendor/campaign/all" replace />, index: true },
            {
              path: ':id',
              children: [
                {
                  path: '',
                  element: (
                    <DetailCampaignCardPage
                      title="menu.campaign.details"
                      activeRole="roles.VENDOR"
                      paths={identifiedPaths}
                    />
                  ),
                },
                {
                  path: 'milestones',
                  children: [
                    {
                      path: '',
                      element: (
                        <CampaignMilestonesPage
                          title="section.admin.dashboard.label.milestones"
                          activeRole="roles.VENDOR"
                          paths={identifiedPaths}
                        />
                      ),
                    },
                    {
                      path: ':milestone_id',
                      children: [
                        { path: '', element: <>Milestone Detail</> },
                        {
                          path: 'all-progress',
                          element: (
                            <VendorAcceptedMilestoneProgeressPage
                              title="menu.vendors.milestone_progress"
                              activeRole="roles.VENDOR"
                              paths={identifiedPaths}
                            />
                          ),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: 'progresses',
                  element: (
                    <VendorAcceptedMilestoneProgeressPage
                      title="menu.vendors.milestone_progress"
                      activeRole="roles.VENDOR"
                      paths={identifiedPaths}
                    />
                  ),
                },
                {
                  path: 'invoices',
                  element: (
                    <VendorMilestoneInvoicesPage
                      title="menu.vendors.milestone_invoices"
                      activeRole="roles.VENDOR"
                      paths={identifiedPaths}
                    />
                  ),
                },
                {
                  path: 'payment-history-progress',
                  element: (
                    <VendorPaymentHistoryProgressPage
                      title="menu.vendors.milestone_invoices"
                      activeRole="roles.VENDOR"
                      paths={identifiedPaths}
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'operator',
      children: [
        { element: <Navigate to="/vendor/operator/all" replace />, index: true },
        {
          path: 'all',
          element: (
            <AllOperatorPage
              title="menu.operators"
              activeRole="roles.VENDOR"
              paths={identifiedPaths}
            />
          ),
        },
      ],
    },
  ],
};
