export const ar = {
  button: {
    add_vendor: 'إضافة البائع',
    view: 'منظر',
    edit_vendor: 'تحرير البائع',
    delete_vendor: 'حذف البائع',
    approve: 'يعتمد',
    reject: 'رفض البائع',
    upload_new_invoice: 'تحميل فاتورة جديدة',
  },
  form: {
    name: {
      label: 'اسم البائع',
      required: 'اسم البائع مطلوب',
    },
    field: {
      label: 'مجال الشركة',
      required: 'مجال الشركة مطلوب',
    },
    type_project_handled: {
      label: 'نوع مقبض المشروع',
      required: 'نوع مقبض المشروع مطلوب',
    },
    logo: {
      label: 'تحميل شعار الشركة',
      required: 'شعار الشركة مطلوب.',
    },
    similiar_project: {
      label: 'تحميل مشروع مشابه',
      required: 'مشروع مماثل مطلوب.',
    },
    analities: {
      label: 'قم بتحميل أي ملفات تساعدنا على فهم تصنيفاتك بشكل أفضل',
      required: 'ملف التصويري مطلوب',
    },
    date_added: {
      label: 'تم إضافة التاريخ',
    },
    email: {
      label: 'بريد إلكتروني',
    },
    finished_campaigns: {
      label: 'الحملات الانتهاء',
    },
    fired_campaigns: {
      label: 'الحملات المطلقة',
    },
    income_status: {
      label: 'حالة الدخل',
    },
  },
};
