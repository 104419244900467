import { ar as menu } from 'src/layouts/translation/ar';

import { ar as section } from 'src/sections/translation/ar';
import { ar as auth } from 'src/sections/auth/translate/ar';

const ar = {
  roles: {
    CLUSTER_ADMIN: 'مسؤل',
    OPERATOR: 'المشغل أو العامل',
    EVALUATOR: 'المقيِّم',
    VENDOR: 'بائع',
    DONOR: 'جهات مانحة',
  },
  place_types: {
    HOSPITAL: 'مستشفى',
    RESTAURANT: 'مطعم',
    SCHOOL: 'مدرسة',
  },
  caption: {
    empty_data: 'لاتوجد بيانات',
    rows_per_page: 'صفوف لكل صفحة',
    dense: 'جدول كثيف',
    type: {
      CAMPAIGN: 'حملة',
    },
    status: {
      approved: 'موافقة',
      finished: 'انتهى',
      pending: 'قيد الانتظار',
      rejected: 'مرفوض',
      published: 'نشرت',
      default: 'تقصير',
      APPROVED: 'موافقة',
      PENDING: 'قيد الانتظار',
      SUCCESS: 'نجاح',
      CANCELLED: 'ألغيت',
      FAILED: 'فشل',
      DECLINED: 'انخفض',
      EXPIRED: 'منتهي الصلاحية',
      REJECTED: 'مرفوض',
      DONE: 'منتهي',
    },
    empty_campaign: 'الحملة فارغة',
    empty_description_campaign:
      'تبدو مثل التصفية أو الفرز بيانات فارغة في قاعدة البيانات الخاصة بنانا',
  },
  notification: {
    success: {
      created_project: 'تم إنشاء المشروع',
      created_campaign: 'تم إنشاء الحملة',
      deleted_campaign: 'تم حذف الحملة',
      approveed_project: 'تمت الموافقة على المشروع',
      rejected_project: 'تم رفض المشروع',
      project_edit_request: 'تم تقديم المشروع',
      approveed_campaign: 'تمت الموافقة على الحملة',
      rejected_campaign: 'تم رفض الحملة',
      campaign_edit_request: 'تم تقديم الحملة',
      created_item: 'تم إنشاء العنصر',
      updated_item: 'تم تحديث العنصر',
      rejected_item: 'تم رفض العنصر',
      created_vendor: 'تم إنشاء البائع',
      edited_vendor: 'تم تحرير البائع',
      approve_vendor: 'تمت الموافقة على البائع',
      reject_vendor: 'تم رفض البائع',
      apply_campaign: 'تم تقديم طلبك',
      approved_vendor_campaign: 'تمت الموافقة على البائع',
      rejected_vendor_campaign: 'تم رفض البائع',
      add_progress_milestone: 'تم تقديم تقدمك',
      accept_progress_milestone: 'تم قبول تقدمك',
      reject_progress_milestone: 'تم رفض تقدمك',
      add_invoice_progress: 'تم تقديم فاتورتك',
      pay_to_vendor_invoice: 'تم تقديم دفعتك لتلك الفاتورة',
    },
  },
  auth,
  menu,
  section,
};

export default ar;
