// // Add the following code snippet to your application before any other JavaScript/TypeScript code!
// // import { TracingInstrumentation } from '@grafana/faro-web-tracing';
// // For example put the code in your root index.[ts|js] file, right before you initialize your SPA / App.
// import { initializeFaro, getWebInstrumentations } from '@grafana/faro-web-sdk';

// export const faro = initializeFaro({
//   url: 'https://faro-collector-prod-ap-south-1.grafana.net/collect/54cc3be84ba2d8d1a33b3c9ff77fc0f4',
//   app: {
//     name: 'ommar-pwa',
//     version: import.meta.env.VITE_SERVICE_VERSION, // Optional, but recommended
//     environment: import.meta.env.VITE_SERVICE_ENV,
//   },
//   instrumentations: [
//     // Mandatory, overwriting the instrumentations array would cause the default instrumentations to be omitted
//     ...getWebInstrumentations(),

//     // Initialization of the tracing package.
//     // This packages is optional because it increases the bundle size noticeably. Only add it if you want tracing data.
//     // new TracingInstrumentation(),
//   ],
// });

import * as Sentry from '@sentry/react';

if (
  import.meta.env.VITE_SERVICE_BUILD_ENV === 'staging' ||
  import.meta.env.VITE_SERVICE_BUILD_ENV === 'prod'
) {
  Sentry.init({
    dsn: 'https://9f683a8e83665ab97203e42d77100203@o4506388573257728.ingest.sentry.io/4506388938162176',
    environment: import.meta.env.VITE_SERVICE_BUILD_ENV,
    release: import.meta.env.VITE_SERVICE_VERSION,
    integrations: [
      new Sentry.BrowserTracing(),
      new Sentry.Replay(),
      new Sentry.Feedback({
        // Additional SDK configuration goes in here, for example:
        colorScheme: 'light',
      }),
    ],
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/worker.*\.ommar\.net/],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
  });
}
