import { ar as donor } from '../donor/translation/ar';
import { ar as admin } from '../admin/translation/ar';
import { ar as vendor } from '../vendor/translation/ar';
import { ar as project } from '../project/translation/ar';
import { ar as operator } from '../operator/translation/ar';
import { ar as campaign } from '../campaign/translation/ar';
import { ar as evaluator } from '../evaluator/translation/ar';
import { ar as marketplace } from '../marketplace-item/translation/ar';

export const ar = {
  donor,
  admin,
  vendor,
  project,
  operator,
  campaign,
  evaluator,
  marketplace,
};
