export const ar = {
  title: {
    all_evaluation_request: 'كل طلب التقييم',
  },
  form: {
    mosque_name: {
      label: 'اسم المسجد',
    },
    location: {
      label: 'المواقع',
    },
    request_date: {
      label: 'تاريخ الطلب',
    },
  },
};
