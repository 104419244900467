// @mui
import { grey } from '@mui/material/colors';
import { Box, Card, Stack, styled, Divider } from '@mui/material';

// hooks
import { useResponsive } from 'src/hooks/use-responsive';

// components
import Logo from 'src/components/logo';

import LocationPopover from '../common/location-popover';
import LanguagePopover from '../common/language-popover';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

const BannerCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#fff',
  padding: 0,
  background: 'url(/assets/images/banner/abu_dhabi.png)',
  backgroundSize: '90%',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  width: 631,
  height: 466,
  boxShadow: 'none',
  border: '3px solid #F4F4F4',
  boxSizing: 'border-box',
  borderRadius: 30,
  transition: 'all 0.5s',

  [theme.breakpoints.down('lg')]: {
    width: 400,
    height: 300,
  },
}));

const HeaderMenuWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',

  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
  },
}));

export default function AuthClassicLayout({ children, image, title }: Props) {
  const mdUp = useResponsive('up', 'md');

  const renderContent = (
    <Stack
      direction="column"
      spacing={3}
      sx={{
        width: 1,
        mx: 'auto',
        maxWidth: 587,
        px: { xs: 2, md: 4 },
        py: 4,
      }}
    >
      <HeaderMenuWrapper>
        <Logo />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: 2,
          }}
        >
          <LocationPopover data-cy="org.home-page.location-popover" />
          <LanguagePopover data-cy="org.home-page.language-popover" />
        </Box>
      </HeaderMenuWrapper>
      <Divider />
      <Box sx={{ flexGrow: 0.15 }} />
      {children}
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      spacing={10}
      alignItems="center"
      justifyContent="center"
      sx={{
        bgcolor: grey[50],
        borderLeft: `1px solid ${grey[200]}`,
      }}
    >
      <Box sx={{ bgcolor: 'white', borderRadius: '30px' }}>
        <BannerCard />
      </Box>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      {renderContent}

      {mdUp && renderSection}
    </Stack>
  );
}
