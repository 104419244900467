import { useState } from 'react';
// material
import { m } from 'framer-motion';

// @mui
import {
  Box,
  Button,
  MenuItem,
  TextField,
  Typography,
  ListItemIcon,
  ListItemText,
  InputAdornment,
} from '@mui/material';

// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

const COUNTRIES = [
  {
    value: 'london',
    city: 'London',
    country: 'UK',
    icon: 'flagpack:gb-ukm',
  },
  {
    value: 'mecca',
    city: 'Mecca',
    country: 'AR',
    icon: 'flagpack:sa',
  },
  {
    value: 'jakarta',
    city: 'Jakarta',
    country: 'ID',
    icon: 'flagpack:id',
  },
  {
    value: 'bristol',
    city: 'Bristol',
    country: 'UK',
    icon: 'flagpack:gb-ukm',
  },
  {
    value: 'medina',
    city: 'Medina',
    country: 'AR',
    icon: 'flagpack:sa',
  },
  {
    value: 'bali',
    city: 'Bali',
    country: 'ID',
    icon: 'flagpack:id',
  },
];

// ----------------------------------------------------------------------

export default function LocationPopover() {
  const popover = usePopover();
  const [searchValue, setSearchValue] = useState('');
  const [currentLocation, setCurrentLocation] = useState(COUNTRIES[0]);
  const [coutries, setCountries] = useState(COUNTRIES);

  const handleSwitch = (value: string, city: string, country: string, icon: string) => {
    setCurrentLocation({ value, city, country, icon });

    popover.onClose();

    setCountries(COUNTRIES);
  };

  const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);

    setCountries(
      COUNTRIES.filter((country) => country.value.toLowerCase().includes(searchValue.toLowerCase()))
    );
  };

  return (
    <>
      <Button
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          ...(popover.open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <Iconify
          icon="eva:pin-outline"
          color="text.secondary"
          sx={{ borderRadius: 0.65, width: 28 }}
        />
        <Typography variant="body2" color="text.secondary" sx={{ ml: 1, mr: 0.5 }}>
          {currentLocation.city}, {currentLocation.country}
        </Typography>
      </Button>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 1.5 }}>
        <Box sx={{ py: 1 }}>
          <TextField
            fullWidth
            placeholder="Search"
            variant="outlined"
            size="small"
            onChange={handleChangeSearch}
            InputProps={{
              inputProps: { min: 1 },
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-outline" />
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          {coutries.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLocation.value}
              onClick={() => handleSwitch(option.value, option.city, option.country, option.icon)}
              sx={{ py: 1, px: 2.5 }}
            >
              <ListItemIcon>
                <Iconify icon={option.icon} sx={{ boxShadow: '0px 0px 4px #00000040' }} />
              </ListItemIcon>
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.city}, {option.country}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </CustomPopover>
    </>
  );
}
